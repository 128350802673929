@import "~antd/dist/antd.less";

// @primary-color: #0000ff;

@import "./shareds.less";

.logo-auth {
  width: 220px !important;
  margin: 0 auto;
  margin-top: 40px;
}

.show-sidebar-bg {
  background: #ffffff !important;
}

.primary {
  color: #1890FF !important;
}

