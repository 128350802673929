/* Layout Auth */

.ant-layout.layout-auth {
  min-height: 100vh;
}

.ant-layout.layout-auth .login-form {
  max-width: 400px;
}

.ant-layout.layout-auth .login-form-forgot {
  float: right;
}

.ant-layout.layout-auth .login-form-button {
  width: 100%;
}

/* Layout Main */

.ant-layout.layout-main {
  min-height: 100vh;
}

.sider-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow-y: scroll;
}

// .sider-wrapper ul.top {
//   flex: 1;
// }

// .sider-wrapper ul.bottom {
//   flex: 0;
// }

.logo {
  margin: 2px auto;
  width: 60px;
}

.ant-layout-header.header-background {
  background-color: #ffffff;
  padding: 0;

  .ant-input-search.ant-input-affix-wrapper {
    padding-left: 0px;
    width: 40vw;
    margin-right: 16px;
    flex-flow: row-reverse;

    .ant-input-suffix {
      margin-right: 10px;
      font-size: 16px;

      .ant-input-search-icon {
        margin-left: 0;
        margin-right: 0;

        &::before {
          border-left: none;
        }
      }
    }
  }

  // .trigger {
  //   font-size: 18px;
  //   line-height: 64px;
  //   padding: 0 24px;
  //   cursor: pointer;
  //   transition: color 0.3s;

  //   &:hover {
  //     color: @primary-color;
  //   }
  // }
}

.ant-layout.content {
  padding-top: 10px;
  height: calc(100vh - 66px);

  .ant-page-header.ant-page-header-ghost {
    padding-bottom: 0;
    padding-top: 10px;
  }

  .ant-layout-content.content {
    padding: 24px;
    padding-top: 12px;
    overflow-y: scroll;
    // height: calc(100vh - 66px - 72px - 16px);
  }
}

// .ant-btn {
//   margin-right: 8px;
// }

.ant-menu-item {
  &.no-margin {
    margin: 0px !important;
  }
  &.no-border {
    border-bottom: 2px solid transparent !important;
    cursor: none;

    &.ant-menu-item-active {
      border-bottom: 2px solid transparent !important;
      cursor: none;
    }
  }
}

.ant-table-wrapper {
  .ant-pagination-total-text {
    line-height: 32px;
  }

  .table-dropdown-btn.ant-btn {
    margin-left: 18px;
    padding: 4px 8px;
  }
}

// .ant-avatar.ant-avatar-icon > .anticon {
//   font-size: 20px;
// }

// .ant-page-header.has-breadcrumb {
//   margin: 0 24px;
// }

// .ant-layout-footer.footer {
//   text-align: center;
// }

// th.ant-table-cell {
//   padding-top: 0 !important;
//   padding-bottom: 0 !important;
// }

// .ant-form-item-control-input .ant-transfer {
//   display: flex;
//   align-items: center;

//   .ant-transfer-list {
//     flex: auto;
//     height: 400px;
//   }

//   .ant-transfer-operation {
//     margin-left: 16px;
//   }
// }

.ant-upload-drag-container {
  span {
    font-size: 20px;
    margin-right: 10px;
  }
}
