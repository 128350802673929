/* Maintenance */

.maintenance {
  // background-image: url("/images/brick-wall.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
}

/* Spinner */

.verticalCenterWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.ant-spin span i {
  background-color: #666666;
}

